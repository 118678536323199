import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const contact = () => {

  const bold_style= "font-extrabold text-yellow-400"
  const li_style = "pl-0 sm:pl-5 md:pl-10 lg:pl-14 pt-5 md:max-w-2xl list-decimal"
  const title_style=`inline-block border-b-2 border-zinc-700 text-white
  font-bold text-3xl md:text-4xl xl:text-4xl pb-1`
  const dot_style="text-yellow-500 text-3xl md:text-4xl "
  const par_style="mt-10 mb-14 md:max-w-7xl text-base md:text-lg"
  
  const image = 
    <StaticImage
    className="!absolute top-0 bottom-0 left-0 right-0 z-0 opacity-80"
    imgClassName="absolute top-0 bottom-0 left-0 right-0 z-0 "
    src="../../static/office.jpg"
    alt="Apple Macbook Dark"
    placeholder="blurred"
    />

  return (
    <>
      <Seo
      title="Chi siamo | FvgLab - Soluzioni informatiche personalizzate per aziende e privati" 
      description={"Vuoi conoscere meglio FvgLab? Siamo un'azienda informatica specializzata in soluzioni personalizzate per le tue esigenze digitali. Grazie alla nostra competenza tecnica e alla passione per l'innovazione, ti offriamo servizi e prodotti di alta qualità."}/>
      
      <Layout>
        
        <SimpleBanner title="Su di noi" back={image} />
        
        <section className="px-10 md:px-20 lg:px-40 pt-14 md:pt-20 lg:pt-40 text-slate-400">
          <h1 className={title_style}>
            Il riferimento territoriale di fiducia
            <span className={dot_style}>.</span>
          </h1>
          
          <p className={par_style}>
            <b className={bold_style}>FVGLAB</b> è un'azienda operante nel settore ICT in espansione che nasce dall’unione delle competenze e
            <b className={bold_style}> professionalità di appassionati</b> del mondo informatico con la missione aziendale di offrire soluzioni innovative,
            semplici e affidabili.
            Il nostro team è composto da consulenti, tecnici di <b className={bold_style}>consolidata esperienza</b>, ricercatori e giovani collaboratori
            capaci e in continua formazione che nutrono un grande interesse per il mondo informatico che offre sempre nuovi
            stimoli e sfide con l’obiettivo di esaudire le aspettative del cliente e proporre soluzioni realizzate con
            <b className={bold_style}> tecnologie innovative</b>.
          </p>          
        </section>

        <section className="px-10 md:px-20 lg:px-40 py-10 md:py-20 lg:py-40 text-slate-400">

          <h1 className={title_style}>
            La nostra missione
            <span className={dot_style}>.</span>
          </h1>

          <p className={par_style}>
            Ci prensentiamo al mercato non come fornitori ma come <b className={bold_style}>Partner</b> ponendoci tre obiettivi:
            <li className={li_style}>
              Fornire le <b className={bold_style}>migliori soluzioni software e hardware</b>, considerando le esigenze del Partner e il giusto equilibrio fra qualità e costo, garantendo un servizio competente e puntuale.
            </li>
            <li className={li_style}>
              Assistere il Partner affiancandolo in tutte le tappe della sua <b className={bold_style}>crescita tecnologica</b>, dallo sviluppo dell’idea e definizione del progetto alla sua realizzazione, nonchè alla formazione, mantenimento e future evoluzioni.
            </li>
            <li className={li_style}>
              Dare a tutti i Partner l’<b className={bold_style}>assistenza necessaria a migliorare l’efficienza</b> delle proprie strutture operative attraverso momenti di confronto per l’analisi dei processi aziendali.
            </li>
          </p>
        </section>

      </Layout>
    </>
  )
}

export default contact
